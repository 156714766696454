import cx from 'classnames'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHomepageTabsContext } from '../../contexts/HomepageTabsContext'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import { Button2 } from '../button2'
import { Container } from '../container'
import { HeroCarousel } from '../hero-carousel'
import styles from './hero3.module.css'
import partnerLogo1 from './partner-logo.png'
import partnerLogo2 from './partner_logo_2.png'

import googleLogo from './google.svg'

export const Hero3 = ({ isShowNewCalc }) => {
  const scroll = useSmoothScroll()
  const { activeTab, dispatchAction } = useHomepageTabsContext()
  const { t } = useTranslation()

  const openCalcForm = selector => {
    if (selector === 'calculator2') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: `click_cta_calculator_header`,
          pageURL: window.location.pathname,
        })
      if (window.innerWidth <= 1024) {
        if (isShowNewCalc) {
          const currentLanguage = window.location.pathname.includes('/en');
          if (currentLanguage) {
            window.location.href = '/en/calculator'; // Redirect to the English calculator page
          } else {
            window.location.href = '/calculator'; // Redirect to the regular calculator page
          }
        }else{
          dispatchAction({ type: 'OPEN_TAB1' })
          scroll.animateScroll(document.getElementById(selector))
        }
      }else{
        dispatchAction({ type: 'OPEN_TAB1' })
        scroll.animateScroll(document.getElementById(selector))
      }

    }

   
  }

  // const openOrderForm = selector => {
  //   dispatchAction({ type: 'OPEN_TAB2' })
  //   scroll.animateScroll(document.getElementById(selector))
  // }

  const scrollToReviewsSection = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  const isEn = document.location.pathname.includes('/en')

  const calculateBtn = () => {
    const userData = localStorage.getItem('order-food-contact-information')
    const data = JSON.parse(userData)

    const name = data?.name
    const phone = data?.phone
    const email = data?.email
    const zip = data?.deliveryAddress?.components?.postcode
      ? data?.deliveryAddress?.components?.postcode
      : ''
    const city = data?.city?.label ? data?.city?.label : ''
    const state = 'CZ'

    let firstName = ''
    let lastName = ''

    if (name) {
      const nameParts = name.split(' ')
      firstName = nameParts[0]
      lastName = nameParts.slice(1).join(' ')
    }

    const userInfoData = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state,
    }

    // setTimeout(() => {
    //   window.dataLayer.push({
    //     event: 'click_hp_calculate_price',
    //     pageURL: window.location.pathname,
    //     pageType: 'Purchase',
    //     user_data: userInfoData,
    //   });

    // }, 300);
  }

  const onlineBtn = () => {
    const userData = localStorage.getItem('order-food-contact-information')
    const data = JSON.parse(userData)

    const name = data?.name
    const phone = data?.phone
    const email = data?.email
    const zip = data?.deliveryAddress?.components?.postcode
      ? data?.deliveryAddress?.components?.postcode
      : ''
    const city = data?.city?.label ? data?.city?.label : ''
    const state = 'CZ'

    let firstName = ''
    let lastName = ''

    if (name) {
      const nameParts = name.split(' ')
      firstName = nameParts[0]
      lastName = nameParts.slice(1).join(' ')
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state,
    }
    // setTimeout(() => {
    //   window.dataLayer.push({
    //     event: 'click_hp_order_online',
    //     pageURL: window.location.pathname,
    //     pageType: 'Purchase',
    //     user_data: userInfo,
    //   });

    // }, 300);

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `click_cta_online_order_header`,
        pageURL: window.location.pathname,
      })

    window.location.href = isEn ? '/en/order-food-online' : '/order-food-online'
  }

  return (
    <section className={styles.hero} style={{ height: '880px' }}>
      <Container isWide={true}>
        <div className={styles.heroInner}>
          <div className={styles.heroInfo}>
            <h1 className={styles.heroTitle}>
              <Trans i18nKey="home.hero.titleBlueghost">
                <span>Nejlépe</span> hodnocená krabičková dieta
              </Trans>
            </h1>
            <div className={styles.heroRating}>
              <div className={styles.heroRatingWrapper}>
                <div className={styles.heroReviewRating}>
                  <div className={styles.heroReviewStars}>
                    <span style={{ width: '95%' }}></span>
                  </div>
                </div>
                <div style={{ marginRight: '12px' }}>
                  <p style={{ fontWeight: 'bold', fontSize: '24px' }}>4,6</p>
                </div>
                <div>
                  <img
                    src={googleLogo}
                    alt="google logo"
                    width={24}
                    height={24}
                    style={{ display: 'block' }}
                  />
                </div>
              </div>
              <a
                className={styles.heroReviewLink}
                onClick={() => scrollToReviewsSection('reviews')}
              >
                <span style={{ textDecoration: 'underline' }}>
                  300+ kladných zákaznických hodnocení
                </span>
              </a>
            </div>
            <div
              className={cx(styles.buttons, styles.buttonWrapper)}
              id="sticky-buttons"
              onClick={() => calculateBtn()}
            >
              <Button2
                color="primary"
                className={styles.button}
                handleClick={e => {
                  openCalcForm('calculator2')
                }}
              >
                <svg
                  className={styles.buttonIcon}
                  fill="none"
                  height="25"
                  viewBox="0 0 25 25"
                  width="25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m13.5413 15.625h2.0834m-10.41669 6.25v-16.66667c0-.55253.21949-1.08244.61019-1.47314s.92061-.61019 1.47314-.61019h10.41666c.5525 0 1.0824.21949 1.4731.61019s.6102.92061.6102 1.47314v16.66667l-3.125-2.0833-2.0833 2.0833-2.0833-2.0833-2.0834 2.0833-2.08329-2.0833zm4.16666-14.58333h6.25003zm0 4.16663h6.25003z"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5625"
                  />
                </svg>
                {t('home.hero.cta-2Blueghost')}
              </Button2>
              <a style={{ color: '#fff' }} onClick={onlineBtn}>
                <Button2 className={styles.button} color="secondary">
                  {t('home.hero.cta-1')}
                </Button2>
              </a>
            </div>
          </div>
          <div className={styles.heroCarouselWrapper}>
            <div className={styles.heroCarousel}>
              {/* <img
                src={CarouselPlaceholder}
                style={{ maxWidth: "100%" }}
                alt="placeholder"
              /> */}
              {/* <HeroSvgCarousel /> */}
              <HeroCarousel />
              <div className={styles.heroCarouselHiddenText}>
                <strong>carousel</strong>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.partnerBanner}>
        <Container isWide={true}>
          <div className={styles.partnerBannerInner}>
            <span className={styles.partnerBannerTitle}>
              {t('home.hero3.newTitle')}
            </span>
            <div className={styles.imageCont}>
              <a
                href="https://www.formfactory.cz/"
                target="_blank"
                rel="nofollow"
              >
                <img
                  src={partnerLogo1}
                  className={styles.partnerBannerImg}
                  alt="partner-logo-1"
                />
              </a>
              <a
                href="https://oktagonmma.com/cs/"
                target="_blank"
                rel="nofollow"
              >
                <img
                  src={partnerLogo2}
                  className={styles.partnerBannerImg}
                  alt="partner-logo-2"
                />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </section>
  )
}
