import React, { useState } from 'react'
import { CityCard } from '../card'

import styles from './styles.module.css'
import praha from '../card/images/praha.jpg'
import plzen from '../card/images/plzen.jpg'
import liberec from '../card/images/liberec.jpg'
import hradec from '../card/images/hradec.jpg'
import pardubice from '../card/images/pardubice.jpg'
import kladno from '../card/images/kladno.jpg'
import jablonec from '../card/images/jablonec.jpg'
import mladaBoleslav from '../card/images/mlada-boleslav.jpg'
import brno from '../card/images/brno.jpg'

import Slider from 'react-slick'

export const CityTabs = ({ selectCurrentCity }) => {
  const settings = {
    arrows: false,
    slidesToShow: 9,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 6,
          slidesToScroll: 2,
          dots: false,
          variableWidth: true,
          focusOnSelect: true,
        },
      },
    ],
  }

  const [currentTab, setCurrentTab] = useState('1')
  const tabs = [
    {
      id: 1,
      tabTitle: 'Praha',
      title: 'Title 1',
      time: '2 hod. okno pro doručení',
      img: praha,
      link: '/krabickova-dieta-praha-a-okoli',
      event: 'click_delivery_block_prague'
    },
    {
      id: 2,
      tabTitle: 'Brno',
      title: 'Title 4',
      time: 'Dovoz ve večerních hodinach mezi 18:00-23.',
      img: brno,
      link: '/krabickova-dieta-brno',
      event: 'click_delivery_block_brno'

    },
    {
      id: 3,
      tabTitle: 'Plzeň',
      title: 'Title 2',
      time: '3 hod. okno pro doručení',
      img: plzen,
      link: '/krabickova-dieta-plzen',
    },
    {
      id: 4,
      tabTitle: 'Liberec',
      title: 'Title 3',
      time: 'Dovoz ve večerních hodinach mezi 17-22.',
      img: liberec,
      link: '/krabickova-dieta-liberec',
      event: 'click_delivery_block_liberec'

    },
    {
      id: 5,
      tabTitle: 'Hradec Králové',
      title: 'Title 4',
      time: 'Dovoz ve večerních hodinach mezi 16:30-22.',
      img: hradec,
      link: '/krabickova-dieta-hradec',
      event: 'click_delivery_block_hradec_kralove'

    },
    {
      id: 6,
      tabTitle: 'Pardubice',
      title: 'Title 4',
      time: 'Dovoz ve večerních hodinach mezi 16:30-22.',
      img: pardubice,
      link: '/krabickova-dieta-pardubice',
      event: 'click_delivery_block_pardubice'

    },
    {
      id: 7,
      tabTitle: 'Kladno',
      title: 'Title 4',
      time: '2 hod. okno pro doručení',
      img: kladno,
      link: '/krabickova-dieta-kladno',
      event: 'click_delivery_block_kladno'

    },
    {
      id: 8,
      tabTitle: 'Jablonec',
      title: 'Title 4',
      time: 'Dovoz ve večerních hodinach mezi 18-22.',
      img: jablonec,
      link: '/krabickova-dieta-jablonec',
      event: 'click_delivery_block_jablonec'

    },
    {
      id: 9,
      tabTitle: 'Mladá Boleslav',
      title: 'Title 4',
      time: 'Dovoz ve večerních hodinach mezi 16-22.',
      img: mladaBoleslav,
      link: '/krabickova-dieta-mlada-boleslav',
      event: 'click_delivery_block_mlada_boleslav'
    },
  ]

  const handleTabClick = (e, city) => {
    if (city.event) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: city.event,
          pageURL: window.location.pathname
        })
    }

    setCurrentTab(e.target.id)
    selectCurrentCity(city.tabTitle)
  }

  return (
    <div className={styles.tabsContainer}>
      <Slider {...settings} className="city-tabs-carousel">
        {tabs.map((tab, i) => (
          <div className={styles.tabs}>
            <button
              key={i}
              id={tab.id}
              disabled={currentTab === `${tab.id}`}
              onClick={(e) => handleTabClick(e, tab)}
            >
              {tab.tabTitle}
            </button>
          </div>
        ))}
      </Slider>

      <div className={styles.tabContent}>
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <CityCard title={tab.tabTitle} img={tab.img} time={tab.time} link={tab.link} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
