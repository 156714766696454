import { Experiment, Variant } from '@marvelapp/react-ab-test'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { BounceLoader } from 'react-spinners'
import { About } from '../components/about'
import { ChristmasPromoModal } from '../components/christmas-promo-modal'
import { DietologFormModal } from '../components/dietolog-consult-modal'
import { DiscountSection } from '../components/discount'
import { FAQ } from '../components/faq'
import { FAQ2 } from '../components/faq2'
import { FoodCarouselSection } from '../components/food'
import { FoodCarouselSection2 } from '../components/food2'
import { FoodCarouselSection3 } from '../components/food3'
import { Footer } from '../components/footer'
import { Footer2 } from '../components/footer2'
import { Header } from '../components/header'
import { Header2 } from '../components/header2'
import { Hero } from '../components/hero2'
import { Hero3 } from '../components/hero3'
import { HowItWork } from '../components/howitwork'
import { HowItWork2 } from '../components/howitwork-v2'
import { Loader } from '../components/loader'
import { MenuOrderInfo } from '../components/menuOrderInfo'
import { NutritionistSection } from '../components/nutritionist'
import { Order } from '../components/order-form'
import { Order2 } from '../components/order-form2'
import { PreFooter } from '../components/prefooter'
import { Program } from '../components/program'
import { RedirectModal } from '../components/redirect-modal'
import { Reviews } from '../components/reviews'
import { SaleFormModal } from '../components/sale-form-modal'
import { SaleFormModal2 } from '../components/sale-form-modal2'
import SEO from '../components/seo'
import { AffiliateContext } from '../contexts/AffiliateContext'
import { getUTM } from '../helpers'
import Advantages from '../components/advantages'
import { VegeMenuBlock } from '../components/vege-menu'
import { DeliverySection } from '../components/delivery'
import { HowDoWePrepare } from '../components/how-do-we-prepare'
import { Program2 } from '../components/program-v2'
import { Reviews2 } from '../components/reviews2'
import { DeliverySection2 } from '../components/delivery-v2'
import { CookieAgreementModal } from '../components/cookie-agreement-modal'
import { Hero3New } from '../components/hero3-new/hero3-new'
import { FoodCarouselNovinky } from '../components/food-novinky'
import { LocalizedLink } from '../components/localized-link'
import { useTranslation } from 'react-i18next'
import { Hero3Red } from '../components/hero3-red'
import FoodCalculatorFilter from '../components/food-calculator-filter'
import { CalculatorButton } from '../components/calculatorButton'
// import { HomepageTabsProvider, homepageTabsReducer, initialState } from '../contexts/HomepageTabsContext';

// const Loader = () => (
//   <div
//     style={{
//       height: "100vh",
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//     }}
//   >
//     <BounceLoader size={100} color="#3DC383" />
//   </div>
// )
const sectionData = [
  { id: 'calculator', event: 'scroll_calculator' },
  { id: 'food', event: 'scroll_food' },
  { id: 'multi', event: 'scroll_multi_offer' },
  { id: 'demo', event: 'scroll_demo_order_form' },
  { id: 'footer', event: 'scroll_footer' },
  { id: 'food-new', event: 'scroll_new_meals' },
  { id: 'video', event: 'scroll_production_video' },
  { id: 'vege-menu-section', event: 'scroll_vegetarian_menu' },
  { id: 'benefits', event: 'scroll_benefits' },
  { id: 'menu', event: 'scroll_menu' },
  { id: 'programs', event: 'scroll_programs' },
  { id: 'new-meals', event: 'scroll_new_meals' },
  { id: 'delivery-section', event: 'scroll_delivery' },
  { id: 'reviews', event: 'scroll_reviews' },
  { id: 'how-works', event: 'scroll_how_it_works' },
  { id: 'nutritionist', event: 'scroll_nutritionist_consultation' },
  { id: 'faq', event: 'scroll_faq' },
];

const NewHomepage = ({ site }) => {
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate
  const { t } = useTranslation()
  const shouldRenderFoodCalculator = window.innerWidth >= 600

  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState('')
  const webVersions = ['new-calc-A', 'new-calc-B']
  const [price, setPrice] = useState('420')
  const [oldPrice, setOldPrice] = useState(null)
  const [plan, setPlan] = useState('Zhubnout')
  const [lastVisibleSection, setLastVisibleSection] = useState(null);

  useEffect(() => {
    const curVersion = localStorage.getItem('website-version')
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]

    if (!curVersion || !webVersions.includes(curVersion)) {
      localStorage.setItem('website-version', randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      pageURL: '/',
      testovani: websiteVersion,
      event: "ga.pageview"

    })
  }, [websiteVersion])

  const handleClick = () => {
    const isEn = document.location.pathname.includes('/en')
    const path = isEn
      ? document.location.pathname.replace(/^\/en/, '')
      : document.location.pathname
    const url = `${window.location.origin}${path}conditions.pdf`

    window.open(url, '_blank')
  }

  const handleDataPushDatalayer = (event) => {
    window.dataLayer = window.dataLayer || [];
    if (event) {
      window.dataLayer.push({
        event,
        pageURL: window.location.pathname,

      });
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      sectionData.forEach(({ id, event }) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

          if (isVisible && lastVisibleSection !== id) {
            setLastVisibleSection(id);
            handleDataPushDatalayer(event);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastVisibleSection]);
  
  useEffect(() => {
    scrollToHash()
  }, [scrollToHash])

  const scrollToHash = () => {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash
      if (hash) {
        const element = document.querySelector(hash)
        if (element) {
          // Вычисляем координату скроллинга с учетом смещения вниз на 100 пикселей
          let offset = -500

          if (window.innerWidth < 1024) {
            offset = -200 // Смещение для мобильных устройств
          }
          const elementRect = element.getBoundingClientRect()
          const elementTop = elementRect.top + window.pageYOffset
          const scrollToY = elementTop - offset

          // Выполняем скроллинг
          window.scrollTo({ top: scrollToY, behavior: 'smooth' })
        }
      }
    }
  }

  const isShowNewCalc = websiteVersion === 'new-calc-B'
  return (
    // <HomepageTabsProvider
    //   value={{ activeTab: state.activeTab, dispatchAction: dispatch }}
    // >
    <>
      <SEO title="Home" />
      {/* componetns here */}
      {/* <ChristmasPromoModal formName="christmas-promo-form" /> */}
      {!isAffiliate && <SaleFormModal formName="sale-form" />}
      <CookieAgreementModal />
      <RedirectModal />
      <SaleFormModal2 formName="Akce 2 dny" />
      {/* {!isAffiliate && <DietologFormModal formName="konzultace-dietolog" />} */}
      <Hero3 isShowNewCalc={isShowNewCalc} />

      {/* <Hero3New/> */}
      {/* <div className="text-center" style={{fontSize: '22px'}}> */}
      {/* <Button2 color="outline" size="lg" className={styles.outsideButton}> */}
      {/* <LocalizedLink
        onClick={handleClick}
          target="_blank"
          style={{
            padding: "14px 0",
            textDecoration: "underline",
            fontWeight: '700',
            color: "rgb(68, 199, 137)",
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '16px'
          }}
        >
          {t("general.bannerLink")}
        </LocalizedLink> */}
      {/* </Button2> */}
      {/* </div> */}
      {/* blueghost */}
      <div id="calculator2" />
      {/* <OrderFoodOnline /> */}
      {isShowNewCalc && !shouldRenderFoodCalculator && (
       <CalculatorButton/>
      )}

      {!isShowNewCalc ? (
        <Order2 id="calculator" />
      ) : isShowNewCalc && shouldRenderFoodCalculator ? (
        <FoodCalculatorFilter
          plan={plan}
          setPlan={setPlan}
          oldPrice={oldPrice}
          price={price}
          setOldPrice={setOldPrice}
          setPrice={setPrice}
          id="calculator"
        />
      ) : null}
      <FoodCarouselNovinky id="food-new" />
      <Advantages id="benefits"  />
      <Program2 id="programs" homePage />
      <MenuOrderInfo id="demo" />
      {/* {foodVersion === "current-version" ? (<FoodCarouselSection2 />) : (<FoodCarouselSection3 />)} */}
      {/* <FoodCarouselSection2 /> */}
      <FoodCarouselSection3 id="food" />
      <VegeMenuBlock id="vege-menu-section" />
      <NutritionistSection  id='nutritionist'/>
      {/* <DeliverySection id="delivery-section" /> */}
      <DeliverySection2 id="delivery-section" />
      <Reviews2   id="reviews"/>
      <HowItWork2 id="how-works" />
      <HowDoWePrepare  id='video' />
      <DiscountSection id="multi" />
      <FAQ2 id="faq"  />
    </>
    // </HomepageTabsProvider>
  )
}

const OldHomepage = ({ site }) => (
  <>
    <SEO title="Home" />
    {site && <Header menuLinks={site.siteMetadata.menuLinks} />}
    <Hero />
    <Program id="programs" />
    <Order id="calculator" />
    <FoodCarouselSection />
    <About />
    <Reviews id="reviews" />
    <HowItWork />
    <FAQ id="faq" />
    <PreFooter />
    <Footer />
  </>
)

const IndexPage = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  /* AB-test to display specific design version */
  // const [pageVersion, setPageVersion] = useState("")
  // const versions = ["current-version", "new-version"]

  // useEffect(() => {
  //   const curVersion = localStorage.getItem("homepage-version")
  //   const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
  //   const randomVersion = versions[randomIndex]
  //   if (!curVersion || curVersion === undefined) {
  //     localStorage.setItem("homepage-version", randomVersion)
  //     setPageVersion(randomVersion)
  //   } else {
  //     setPageVersion(curVersion)
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log("push datalayer pageVersion", pageVersion)
  //   window.dataLayer &&
  //     window.dataLayer.push({
  //       event: "ga.pageview",
  //       testovani: pageVersion,
  //     })
  // }, [pageVersion])

  // function getRandomInteger(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min
  // }

  // for hiding sticky hero btns on mobile when scroll to main form

  const [isVisible, setIsVisible] = useState(true)

  const getOffsetStart = element => {
    const rect = element?.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return rect ? scrollTop + rect.top : scrollTop
  }

  const getOffsetEnd = element => {
    const rect = element?.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return rect ? scrollTop + rect.top + rect.height : scrollTop
  }

  const listenToScroll = () => {
    const hideElem = document.querySelector('#sticky-buttons')
    const elemWhenHide = document.querySelector('#calculator')
    const offsetToHideFromStart = getOffsetStart(elemWhenHide)
    const offsetToHideFromEnd = getOffsetEnd(elemWhenHide)
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > offsetToHideFromStart && winScroll < offsetToHideFromEnd) {
      isVisible && setIsVisible(false) // to limit setting state only the first time
      hideElem.style.display = 'none'
    } else {
      setIsVisible(true)
      hideElem.style.display = 'flex'
    }
  }

  useEffect(() => {
    const isMobile = window.innerWidth < 812
    isMobile && window.addEventListener('scroll', listenToScroll)
    return () =>
      isMobile && window.removeEventListener('scroll', listenToScroll)
  }, [])

  useEffect(() => {
    window.localStorage.removeItem('deliveryPrice') // necessary for order form
  }, [])

  return (
    <>
      {/* {pageVersion === "current-version" ? (
        <OldHomepage site={site} />
      ) : pageVersion === "new-version" ? (
        <NewHomepage site={site} />
      ) : (
        <Loader />
      )} */}
      {site ? (
        <NewHomepage site={site} isAffiliate={isAffiliate} />
      ) : (
        <Loader />
      )}
    </>
  )
}

export default IndexPage
