// import './carousel.css';
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

import cx from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import Slider from "react-slick"

import { FoodCard } from "./food-card"
import styles from "./food-carousel.module.css"
import maskMobile from "./mask-mob.svg"
import mask from "./mask.svg"
import maskMobileEn from "./mask-mob_en.svg"
import maskEn from "./mask_en.svg"

export const HeroCarousel = () => {
  const { t } = useTranslation()
  const isEn = document.location.pathname.includes("/en")
  const slidesData = [
    {
      img: "/images/new_card_8.png",
      title: t("general.foodNovinky.card5"),
      heading: t("general.foodNovinky.card5"),
      param1: "253",
      param2: "17g",
      param3: "19g",
      param4: "16g",
      flagUrl: "/images/Vietnam.svg",
      flagTitle: "Vietnam",
    },
    {
      img: "/images/new_card_6.png",
      title: t("general.foodNovinky.card6"),
      heading: t("general.foodNovinky.card6"),
      param1: "281",
      param2: "27g",
      param3: "10g",
      param4: "14g",
      flagUrl: "/images/India.svg",
      flagTitle: "Indie",
    },
    {
      img: "/images/new_card_7.png",
      title: t("general.foodNovinky.card7"),
      heading: t("general.foodNovinky.card7"),
      param1: "401",
      param2: "47g",
      param3: "27g",
      param4: "10g",
      flagUrl: "/images/It.svg",
      flagTitle: "Itálie",
    },
    {
      img: "/images/new_card_5.png",
      title: t("general.foodNovinky.card8"),
      heading: t("general.foodNovinky.card8"),
      param1: " 299",
      param2: "8g",
      param3: "23g",
      param4: "18g",
      flagUrl: "/images/Vietnam.svg",
      flagTitle: "Vietnam",
    },
    // {
    //   img: "/images/F5-min.jpg",
    //   title: t("general.food.card5Title"),
    //   param1: "147",
    //   param2: "16g",
    //   param3: "12g",
    //   param4: "5g",
    // },
    // {
    //   img: "/images/F6-min.jpg",
    //   title: t("general.food.card6Title"),
    //   param1: "234",
    //   param2: "23g",
    //   param3: "25g",
    //   param4: "6g",
    // },
  ]

  const slides = slidesData.map(item => (
    <div className={styles.carouselItem} key={item.title}>
      <FoodCard item={item} />
    </div>
  ))

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    fade: true,
    dotsClass: `slick-dots ${styles.carouselDots}`,
    // appendDots: dots => {
    //   return (
    //     <ul>
    //       {dots.map((item, index) => {
    //         return <li key={index}>{item.props.children}</li>
    //       })}
    //     </ul>
    //   )
    // },
    // customPaging: index => {
    //   return <button>{index + 1}</button>
    // },
  }
  return (
    slidesData !== null &&
    slidesData.length > 0 && (
      <div className={styles.carouselWrapper}>
        <img src={isEn ? maskEn : mask} className={styles.mask} alt="mask" />
        <img
          src={isEn ? maskMobileEn : maskMobile}
          className={styles.maskMobile}
          alt="mask"
        />
        <div className={styles.topEl}>
          <div className={styles.topElText}>{t("home.hero2.title")}</div>
        </div>
        {/* <div className={styles.bottomEl}> */}
        {/* <div className={styles.bottomElText}>Kuřecí medajlonky s omáčkou ze sušených rajčat, ratatouille</div> */}
        {/* </div> */}
        {/* <div className={styles.flagEl}>
          <span className={styles.flagElIcon}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.000427246" width="21" height="21" fill="url(#pattern0)"/>
              <defs>
              <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image0_5_6022" transform="scale(0.00195312)"/>
              </pattern>
              <image id="image0_5_6022" width="512" height="512" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAMAAADDpiTIAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAMZQTFRF////+qCl+4qQ+p+k+p+k+p6k+p6j+p2i+5ac+pug+pug+4uS+pid+5ed+5ac+5ab+5Wb+4+V+4yS+4qQ+4uR+4qR+4mQ+4mQ+4mP/H+G/IaM/IWM/H6F/IOJ/IKJ/H2E/HuC/HqB/HmB/XB4/XZ9/XR8/XN79fX1/W9399LV99bY/XB3/W93/W52/Wlx/WVu/WVu/WZu/ltkYGWq/l1m/lli/lpj/lRd/ldgQUebYmes9fX199XX/0tV/01X/05X/1Fb/1JcXEPXxwAAADl0Uk5TAAIICg4SFCUyNTdNVFddZGeQnaGipqmqq7G5u77DxdPX2tvf4+Xn6Ovs7Ozt7vT19vb5+/v9/f7+b+S5dgAAAyVJREFUeNrt0tVRAwAURNENwYK7W7DglkBw6b8pfhjoAJh555SwexMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAqG5oerl9SCE7i1ODP/8v9F8o53726/7WqTFq2h9Jkua1Jao6byTZskNdS8nkqxnq+hjLuhUqm8uBESrbTs8IlV3EBqXdCaC2WwEIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAiAfxHAM5Wd5InKOgIQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAALg9wJ4pLJuHihNAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAAQgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEwP8N4JHKunmiso4ABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAA+L0AnqnsOC+UJgABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAASAABAAAkAACAABIAAEgAAQAAJAAAgAASAABIAAEAACQAAIAAEgAP4+gJ4NKrvJgREq28uaESpbzcSbFep6HU82zVDXRpLmlR2quhxIktaZJWo6Gk2SpLH0box6+vP5NjyzsntIIe3l6aEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQHWfCqIyJnHqmd8AAAAASUVORK5CYII="/>
              </defs>
            </svg>
          </span>
          <span>Thajsko</span>
        </div> */}
        <Slider {...settings}>{slides}</Slider>
      </div>
    )
  )
}
