import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './check-address.module.css'
import { Input } from '../../pages/order-food-online/components/form'
import { checkDeliveryAddress } from '../../../shared/api/client'
import { Success } from '../../../components/success'
import { Warning } from '../../../components/warning'
import { Error } from '../../../components/error'
import { ButtonCancel } from '../../../components/buttonCancel'

const AddressStatus = {
    DEFAULT: 'default',
    SUCCESS: 'success',
    WARNING: 'warning',
    FAIL: 'fail',
}

export const CheckAddressInput = (props) => {
    const { placeholder, error, fullWidth, cityValue } = props
    const { t } = useTranslation()

    const [addressValue, setAddressValue] = useState('')
    const [formattedAddress, setFormattedAddress] = useState('')
    const [addressStatus, setAddressStatus] = useState(AddressStatus.DEFAULT)
    const [isExistValue, setIsExistValue] = useState(false)
    const [postCode, setPostCode] = useState('')
    const textWithoutSpace = addressValue.trim().replace(/(\s*,\s*)/g, ',')
    const isAddressHostivica = textWithoutSpace
        .toLowerCase()
        .includes('hostivice')
    const addressLabel = isAddressHostivica
        ? `${textWithoutSpace}, Czechia`
        : addressValue
    const addressValidation = async (address) => {
        try {
            const body = await checkDeliveryAddress(address)
            const isAddDeliveryFee = body?.result?.isPointInPolygon
            const addressValueObject = body.result.address
            if (isAddDeliveryFee === true) {
                const adressV = addressValueObject.components.formatted
                setAddressStatus(AddressStatus.SUCCESS)
                setFormattedAddress(addressValueObject.components.formatted)
                setPostCode(addressValueObject.components.postcode)
                setIsExistValue(true)
                window.dataLayer = window.dataLayer || []
                window.dataLayer &&
                    window.dataLayer.push({
                        event: `form_adress_check`,
                        pageURL: `${window.location.pathname}/adress_check=${isAddDeliveryFee}&&adress=${adressV}`,
                        adress_check:isAddDeliveryFee,
                        adress:adressV
                    })

            } else if (isAddDeliveryFee === false) {
                const adressV = addressValueObject.components.formatted

                setAddressStatus(AddressStatus.SUCCESS)
                setFormattedAddress(addressValueObject.components.formatted)
                setPostCode(addressValueObject.components.postcode)
                setIsExistValue(true)
                window.dataLayer = window.dataLayer || []
                window.dataLayer &&
                    window.dataLayer.push({
                        event: `form_adress_check`,
                        pageURL: `${window.location.pathname}/adress_check=${isAddDeliveryFee}&&adress=${adressV}`,
                        adress_check:isAddDeliveryFee,
                        adress:adressV
                    })
            } else if (isAddDeliveryFee === undefined || isAddDeliveryFee === null) {
                window.dataLayer = window.dataLayer || []
                window.dataLayer &&
                    window.dataLayer.push({
                        event: `form_adress_check`,
                        pageURL: `${window.location.pathname}/adress_check=${isAddDeliveryFee}&&adress=${address}`,
                        adress_check:isAddDeliveryFee,
                        adress:address

                    })
                setPostCode('')
                setFormattedAddress('')
                setAddressStatus(AddressStatus.FAIL)
                setIsExistValue(true)
            }
        } catch (error) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer &&
                window.dataLayer.push({
                    event: `form_adress_check`,
                    pageURL: `${window.location.pathname}/adress_check='null'&&adress=${address}`,
                    adress_check:null,
                    adress:address
                })
            setIsExistValue(true)
            setFormattedAddress('')
            setAddressStatus(AddressStatus.FAIL)
            setPostCode('')
        }
    }

    const handleChange = (event) => {
        if (event.target.value) {
            setAddressValue(event.target.value)
        } else {
            setAddressValue('')
        }
        setAddressStatus(AddressStatus.DEFAULT)
    }

    const handleCancelClick = () => {
        setAddressValue('')
        setAddressStatus(AddressStatus.DEFAULT)
        setIsExistValue(false)
    }

    return (
        <div
            className={cx(styles.address, {
                [styles.success]: isSuccessStatus(addressStatus),
                [styles.fail]:
                    isFailStatus(addressStatus) ||
                    (!isSuccessStatus(addressStatus) && error),
                [styles.warning]: isWarningStatus(addressStatus),
                [styles.fullWidth]: fullWidth,
            })}
        >
            <div className={styles.container}>
                <span className={styles.input}>
                    {isExistValue ? (
                        <div
                            className={cx(styles.customInput, {
                                [styles.success]: isSuccessStatus(addressStatus),
                                [styles.fail]:
                                    isFailStatus(addressStatus) ||
                                    (!isSuccessStatus(addressStatus) && error),
                                [styles.warning]: isWarningStatus(addressStatus),
                                [styles.fullWidth]: fullWidth,
                            })}
                        >
                            {(isFailStatus(addressStatus) || !isWarningStatus(addressStatus)) && (
                                <Error
                                    isHomePage
                                    status={addressStatus}
                                    isFailStatus={isFailStatus}
                                    isSuccessStatus={isSuccessStatus}
                                />
                            )}
                            <Success
                                isHomePage
                                status={addressStatus}
                                message={t('home.orderFoodOnline.step2.inputSuccesHomeMsg')}
                                isSuccessStatus={isSuccessStatus}
                            />
                            <Warning
                                isHomePage
                                status={addressStatus}
                                message={t('home.orderFoodOnline.step2.inputErrorMsg')}
                                isWarningStatus={isWarningStatus}
                            />
                        </div>
                    ) : (
                        <Input
                            placeholder={placeholder}
                            onChange={handleChange}
                            error={isFailStatus(addressStatus) ? error : undefined}
                        />
                    )}
                </span>
                <span>
                    <button
                        type="button"
                        onClick={() => {
                            addressValidation(addressLabel)
                        }}
                        disabled={!addressValue}
                        className={styles.button}
                    >
                        {t('home.orderFoodOnline.step2.confirmAddress')}
                    </button>
                </span>
                {!isDefaultStatus(addressStatus) && (
                    <ButtonCancel
                        code={postCode}
                        formattedAddress={formattedAddress}
                        addressValue={addressValue}
                        postCode={postCode}
                        onClick={handleCancelClick}
                        style={{
                            color: isSuccessStatus(addressStatus)
                                ? '#44c789'
                                : isWarningStatus(addressStatus)
                                    ? '#ffcc00'
                                    : isFailStatus(addressStatus) ||
                                        (!isSuccessStatus(addressStatus) && error)
                                        ? '#980000'
                                        : '#87A5C0',
                        }}
                    />
                )}
            </div>
        </div>
    )
}

const isWarningStatus = (status) => {
    return AddressStatus.WARNING === status
}

const isSuccessStatus = (status) => {
    return AddressStatus.SUCCESS === status
}

const isFailStatus = (status) => {
    return AddressStatus.FAIL === status
}

const isDefaultStatus = (status) => {
    return AddressStatus.DEFAULT === status
}
