import React from 'react'

export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="icons8-time-span"
      d="M11,3a8,8,0,1,0,8,8A8.01,8.01,0,0,0,11,3Zm.665,1.366a6.657,6.657,0,0,1,5.969,5.969.666.666,0,0,0,0,1.331,6.657,6.657,0,0,1-5.969,5.969.666.666,0,0,0-1.331,0,6.657,6.657,0,0,1-5.969-5.969.666.666,0,0,0,0-1.331A6.656,6.656,0,0,1,7.81,5.143L11,8.333V5A.667.667,0,0,0,11.665,4.366ZM7.66,6.993A.667.667,0,0,0,7.2,8.138l3.333,3.333a.667.667,0,0,0,.6.182l3.333-.667A.667.667,0,1,0,14.2,9.68l-2.984.6L8.138,7.2A.667.667,0,0,0,7.66,6.993Z"
      transform="translate(-3 -3)"
      fill="#353643"
    />
  </svg>
)

export const PointIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.675"
    height="16"
    viewBox="0 0 13.675 16"
  >
    <path
      id="icons8-pickup-point_1_"
      data-name="icons8-pickup-point (1)"
      d="M22.256,2.8a3.651,3.651,0,0,0-3.619,3.606c-.052,1.874,1.745,4.343,2.818,5.635a1.052,1.052,0,0,0,.8.375,1.072,1.072,0,0,0,.8-.375c1.073-1.305,2.882-3.761,2.818-5.635A3.651,3.651,0,0,0,22.256,2.8Zm0,2.326A1.292,1.292,0,1,1,20.964,6.42,1.3,1.3,0,0,1,22.256,5.127ZM16.342,8.979A.357.357,0,0,0,16.194,9a.378.378,0,0,0-.232.491l.478,1.344a.389.389,0,0,0,.362.258.459.459,0,0,0,.129-.026l1.215-.439a.378.378,0,0,0,.233-.491.408.408,0,0,0-.491-.246l-.853.31-.349-.969A.388.388,0,0,0,16.342,8.979Zm-1.461.53a.356.356,0,0,0-.147.025.378.378,0,0,0-.233.491l1.241,3.4-3.283,1.189a.406.406,0,0,0-.232.517.389.389,0,0,0,.362.258.459.459,0,0,0,.129-.026L16,14.174l1.59,4.368a.389.389,0,0,0,.362.258.459.459,0,0,0,.129-.026.378.378,0,0,0,.233-.491L16.6,13.528l-1.37-3.761A.388.388,0,0,0,14.882,9.509Zm4.285,1.582a.443.443,0,0,0-.155.02l-1.822.672c-.013,0-.026.013-.039.013s-.026.013-.039.013-.026.013-.026.026-.026.013-.026.026-.013.026-.026.026l-.026.026c-.013.013-.013.026-.026.039a.046.046,0,0,1-.013.026c0,.013-.013.026-.013.039s-.013.026-.013.039v.155c0,.013.013.026.013.039a.047.047,0,0,0,.013.039l2.081,5.7a.389.389,0,0,0,.362.258.46.46,0,0,0,.129-.026.378.378,0,0,0,.233-.491l-.84-2.313,4.123-1.5.31.853a.389.389,0,0,0,.362.258A.46.46,0,0,0,23.859,15a.378.378,0,0,0,.232-.491l-.31-.853.853-.31a.384.384,0,0,0-.258-.724l-1.215.439L18.676,14.7l-.84-2.313,1.447-.543a.4.4,0,0,0,.233-.5A.387.387,0,0,0,19.167,11.091Z"
      transform="translate(-12.201 -2.801)"
      fill="#353643"
    />
  </svg>
)

export const ReplaceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14.632"
    viewBox="0 0 16 14.632"
  >
    <path
      id="icons8-replace_2_"
      data-name="icons8-replace (2)"
      d="M8.942,3A7.316,7.316,0,0,0,2.6,13.963l-.78-.238A.665.665,0,1,0,1.432,15l3.233.988.449-3.329A.665.665,0,1,0,3.8,12.477l-.1.731A5.991,5.991,0,0,1,8.942,4.33h.665V3Zm2.235.316-.519,1.227.615.259v0l.612.26L12.4,3.835Zm2.042,1.331L12.77,7.976a.665.665,0,1,0,1.318.178l.1-.731A5.991,5.991,0,0,1,8.942,16.3H8.277v1.33h.665A7.316,7.316,0,0,0,15.284,6.668l.78.238a.665.665,0,1,0,.388-1.272ZM6,15.573,5.481,16.8l.612.259.616.259.515-1.227-.612-.259v0Z"
      transform="translate(-0.942 -3)"
      fill="#353643"
    />
  </svg>
)
