import React from 'react'
import styles from './buttonCancel.module.css'
import cx from 'classnames'

export const ButtonCancel = (props) => {
    const { onClick, disabled, addressValue, style, postCode, city, formattedAddress, code } = props
    const formattedAddressWithCode = formattedAddress.includes(code) ? formattedAddress.replace(code, `${code},`) : formattedAddress;
    const address = formattedAddress ? formattedAddressWithCode : `${addressValue}${postCode ? `, ${postCode}` : ''}${city ? `, ${city}` : ''}`;

    return (
        <span
            className={cx(
                styles.buttonBlock,
                styles.buttonBlockCancel,
                styles.buttonValue
            )}
        >
            <span className={styles.value} style={style}>
                {address}
            </span>
            <button
                type="button"
                onClick={onClick}
                className={cx(styles.button, styles.buttonCancel)}
                disabled={disabled}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                >
                    <path
                        id="icons8-macos-close_1_"
                        data-name="icons8-macos-close (1)"
                        d="M8,3a5,5,0,1,0,5,5A5,5,0,0,0,8,3ZM9.961,9.372a.417.417,0,1,1-.589.589L8,8.589,6.628,9.961a.417.417,0,0,1-.589-.589L7.411,8,6.039,6.628a.417.417,0,0,1,.589-.589L8,7.411,9.372,6.039a.417.417,0,0,1,.589.589L8.589,8Z"
                        transform="translate(-3 -3)"
                        fill="#87a5c0"
                    />
                </svg>{' '}
            </button>
        </span>
    )
}
