import React from 'react'

import { useTranslation } from 'react-i18next'
import { Button2 } from '../button2'
import styles from './styles.module.css'
export const CalculatorButton = () => {
  const { t } = useTranslation()

  const openCalcForm = () => {
    const currentLanguage = window.location.pathname.includes('/en')
    if (currentLanguage) {
      window.location.href = '/en/calculator' 
    } else {
      window.location.href = '/calculator'
    }
  }
  return (
    <div className={styles.calcButtonPar}>
      <Button2 color="primary" handleClick={openCalcForm}>
        <svg
          fill="none"
          height="25"
          viewBox="0 0 25 25"
          width="25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m13.5413 15.625h2.0834m-10.41669 6.25v-16.66667c0-.55253.21949-1.08244.61019-1.47314s.92061-.61019 1.47314-.61019h10.41666c.5525 0 1.0824.21949 1.4731.61019s.6102.92061.6102 1.47314v16.66667l-3.125-2.0833-2.0833 2.0833-2.0833-2.0833-2.0834 2.0833-2.08329-2.0833zm4.16666-14.58333h6.25003zm0 4.16663h6.25003z"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5625"
          />
        </svg>
        {t('home.hero.cta-2Blueghost')}
      </Button2>
    </div>
  )
}
