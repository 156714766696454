import React, { useEffect, useState } from 'react'
import { Button2 } from '../button2'
import { useModal } from '../modal2'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import { LocalizedLink } from '../localized-link'

import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

export const CookieAgreementModal = ({ formName }) => {
  const { t } = useTranslation()

  const { show, hide, RenderModal } = useModal()

  const [isModal, setIsModal] = useState(false)
  const scroll = useSmoothScroll()

  useEffect(() => {
    if (window.localStorage) {
      // Get the expiration date of the previous popup.
      var nextPopup = localStorage.getItem('cookiePopup')

      if (nextPopup > new Date()) {
        return
      }

      // Store the expiration date of the current popup in localStorage.
      var expires = new Date()
      expires = expires.setHours(expires.getHours() + 24)

      localStorage.setItem('cookiePopup', expires)

      setIsModal(true)
      show()
    }
  }, [])

  return (
    <>
      {isModal && (
        <RenderModal className={styles.cookieModal}>
          <h3 className={styles.title}>{t('cookieModal.title')}</h3>
          <p className={styles.p}>
            {t('cookieModal.text')}
            <LocalizedLink
              to="/pravidla_pouzivani_cookies.pdf"
              target="_blank"
              rel="noopener norefferer"
            >
              {t('cookieModal.link')}
            </LocalizedLink>
          </p>
          <div className={styles.btnsBlock}>
            <Button2
              color="secondary"
              className={styles.btn}
              handleClick={hide}
            >
              {t('cookieModal.btn1')}
            </Button2>
            <Button2
              color="secondary"
              className={styles.btn}
              handleClick={hide}
            >
              {t('cookieModal.btn2')}
            </Button2>
          </div>
        </RenderModal>
      )}
    </>
  )
}
