import React, { useState } from 'react'
import cx from 'classnames'
import { Container } from '../container'
import { useTranslation } from 'react-i18next'

import { CityTabs } from './tabs'
import { MapImg, TextImg, TextImgMob } from './images/icons'
import styles from './styles.module.css'
import { CheckAddressInput } from '../../features/form/check-address'

export const DeliverySection2 = ({ id }) => {
  const { t } = useTranslation()
  const [currentCity, setCurrentCity] = useState('Praha')

  return (
    <section id={id && id} className={styles.deliverySection}>
      <Container>
        <h3
          className={cx(
            styles.sectionTitle,
            'fancyUnderlineText sectionTitleNew text-center'
          )}
        >
          Nejpřesnější a nejflexibilnější doprava již v{' '}
          <span>9 městech České republiky</span>
        </h3>
        <p className={styles.sectionSubtitle}>
          Naše krabičky s jídlem jsou nyní k dostání i v dalších městech.
        </p>
        <div className={styles.content}>
          <CityTabs selectCurrentCity={setCurrentCity} />
          <div className={cx(styles.mapImgs, styles.deskVisible)}>
            <MapImg />
            <div className={styles.imgWrap}>
              <TextImg />
            </div>
          </div>
        </div>
        <div className={styles.address}>
          <p className={styles.sectionTitleAddress}>
            Zkontrolujte, zda doručujeme i k vám.
          </p>
          <CheckAddressInput  placeholder={t('home.orderFoodOnline.step2.inputCheckPlaceholder')}
          />
        </div>
        <div className={styles.mob}>
          <div className={cx(styles.mapImgs, styles.mobileVisible)}>
            <MapImg />
            <div className={styles.imgWrap}>
              <TextImgMob />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}