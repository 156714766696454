import cx from 'classnames';
import { FastField, Field, Form, withFormik } from 'formik';
import React, { useContext,  useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AffiliateContext } from "../../contexts/AffiliateContext"
import { getCookie, getReferrer, getUTM } from '../../helpers';
import { Button } from '../button';
import contactFormStyles from './contact-form.module.css';
import styles from './form.module.css';
import { PhoneErrorMessage } from './forms-error-messages';

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phone", "Telefonní číslo musí obsahovat 9 znaků", value =>
    rePhoneNumber.test(value)
  )
});


// import MaskedInput from 'react-text-mask';
export const ContactFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  themeLight,
  btnType = "primary",
  btnText = "Mám zájem",
  label = false,
  horizontal = false,
}) => {
  const { t } = useTranslation()

  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate, affiliateId } = affiliateContext.affiliate

  const handleChange = () => {
    setFieldValue("isAffiliate", isAffiliate);
    setFieldValue("affiliateId", affiliateId);
  }

  useEffect(()  => {
    handleChange();
  }, [affiliateContext, isAffiliate, affiliateId])

  return (
    <>
      <Form
        name="firmy-zajem"
        method="post"
        className={cx(contactFormStyles.contactForm, {
          [contactFormStyles.horizontal]: horizontal,
          [contactFormStyles.themeLight]: themeLight,
        })}
      >
        <div className={cx(styles.inputField, contactFormStyles.inputField)}>
          {label && (
            <label className={contactFormStyles.label}>
              {t("forms.contactForm2.telLabel")}
            </label>
          )}
          {/* <FastField name="phone">
          {({ field }) => (
            <MaskedInput
              {...field}
              mask={[
                "+",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholder="Enter your phone number"
              type="text"
              className={cx(styles.input, contactFormStyles.input)}
            />
          )}
        </FastField> */}
          <FastField
            component="input"
            type="tel"
            name="phone"
            className={cx(styles.input, contactFormStyles.input)}
          />
          {touched.phone && errors.phone && (
            <span className={cx(styles.error, contactFormStyles.error)}>
              {errors.phone}
            </span>
          )}
        </div>
        {/* {values.success && (
        <div className={styles.success}>
          <h4 className="text-center">Successfully sent!</h4>
        </div>
      )} */}
        <Button
          name="submit"
          type={btnType}
          buttonType="submit"
          disabled={isSubmitting}
          className={contactFormStyles.contactFormButton}
        >
          {t("forms.contactForm2.CTA") || btnText}
        </Button>
        <Field
          component="input"
          type="hidden"
          name="isAffiliate"
          value={isAffiliate}
        />
        <Field
          component="input"
          type="hidden"
          name="affiliateId"
          value={affiliateId}
        />
      </Form>
      <div className={contactFormStyles.termsContainer}>
        <span>{t("forms.contactForm2.info")}</span>
      </div>
    </>
  )
}

export const ContactForm2 = withFormik({
  mapPropsToValues: () => ({
    phone: "+420",
    referrer: "",
    ga: "",
    success: false,
    isAffiliate: false,
    affiliateId: null
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(9, <PhoneErrorMessage />)
        .phone(),
    }),
  handleSubmit: async (
    { phone, isAffiliate, affiliateId },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes("/en")
      const testovani = localStorage.getItem("website-version")

      const data = {
        form_name: isEn ? "contact2_en" : "contact2",
        language: isEn ? "EN" : "DE",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        phone,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
        testovani: testovani,
        cjevent: affiliateId,
      }
      const req = await fetch("/api/application", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })

      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)
        const response = await req.json()

        if (isAffiliate) {
          const getParamsData = Object.entries(response)
            .map(([key, value]) => `${key}=${value || 0}`)
            .reduce(
              (previousValue, currentValue) =>
                `${previousValue}&${currentValue}`
            )
          window.location.href = isEn
            ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
            : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
        } else {
          window.location.href = isEn
            ? "/en/thank-you"
            : "/dekovacka-testdrive"
        }

        setTimeout(() => {
          resetForm()
          window.dataLayer.push({
            event: "ga.pageview",
            pageURL: isEn ? "/en/thank-you-contact2" : "/thank-you-contact2",
            pageType: "Purchase",
            testovani: testovani,
          })
        }, 300)
      }
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(ContactFormLayout)
