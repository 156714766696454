import React from 'react'

import styles from './styles.module.css'
import { ClockIcon, PointIcon, ReplaceIcon } from './icons'

export const CityCard = ({ img, title, time, link }) => {
  const goCityPage = () => window.location.href = link
  return (
    <div className={styles.card} onClick={goCityPage}>
      <img src={img} alt="img" className={styles.cardImg} />
      <div className={styles.cardInfo}>
        <h4 className={styles.cardTitle}>{title}</h4>
        <div className={styles.deliveryStatus}>Dovoz zdarma</div>
        <div className={styles.deliveryInfo}>
          <p className={styles.deliveryInfoText}>
            <span>
              <ClockIcon />
            </span>{' '}
            {time}
          </p>
          {title === 'Praha' ? (
            <p className={styles.deliveryInfoText}>
              <span>
                <PointIcon />
              </span>{' '}
              9 odběrových míst
            </p>
          ) : (
            ''
          )}

          <p className={styles.deliveryInfoText}>
            <span>
              <ReplaceIcon />
            </span>{' '}
            Změna času a místa v den dovozu do 12:00
          </p>
        </div>
      </div>
    </div>
  )
}
